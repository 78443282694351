import Header from '../components/header';
import Footer from '../components/footer';
import Accordion, { Color } from '../components/accordion';
import SlideCourse from '../components/slideCourse';
import FooterBrand from '../components/footerBrand';
import ShopeeReviews from '../components/index/shopeeReviews';
import Specific from '../components/index/specific';
import MyStudent from '../components/index/myStudent';
import CoursesUpdate from '../components/index/coursesUpdate';
import StudentReviews from '../components/studentReviews';
import { ResponseData, ResponseDataList } from '../apiStrapi/models/data';
import { ReviewContent } from '../apiStrapi/models/contentType/review';
import { HomeContent } from '../apiStrapi/models/contentType/home';
import { strapiImage } from '../apiStrapi/models/contact';
import VideoPlayer from '../components/videoPlayer';
import { CarouselContent } from '../apiStrapi/models/contentType/carousel';
import { annualPromotionApi, carouselApi, homeApi, reviewApi } from '../apiStrapi/StrapiApiService';
import { AnnualPromotionContent } from '../apiStrapi/models/contentType/annualPromotion';
import PartialLogin, { TypePartial } from '../components/partialLogin';
import Img from '../components/image';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { myCourseApi } from "../apiNest/NestApiService";
import Link from 'next/link';
import MetaDescription from "../components/metaDescription";
import FirstHeader from '../components/index/firstHeader';
import SecondHeader from '../components/index/secondHeader';

interface IndexProps {
  carousel: ResponseDataList<CarouselContent>;
  home: ResponseData<HomeContent>;
  review: ResponseData<ReviewContent>;
  annualPromotion: ResponseData<AnnualPromotionContent>
}

export default function Index({ carousel, home, review, annualPromotion }: IndexProps) {
  const router = useRouter();
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [showAccordion, setShowAccordion] = useState<boolean>(true);

  useEffect(() => {
    myCourseApi().then((value) => {
      setIsSubscriber(Boolean(value?.course_list?.length))
    });
  }, [])

  if (isSubscriber) {
    router.replace("/library")
    return (<></>)
  }
  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  }

  async function setClickedAccor(index: number) {
    setShowAccordion(false);
    await home.data?.q_and_a?.map(async (eachQA, eachIndex) => {
      eachQA.showDetails = (index === eachIndex) && !eachQA.showDetails
    });
    setShowAccordion(true);
  }

  return (
    <>
      <MetaDescription data={ home?.data?.meta_data } />
      <div className="index">
      <Header />
      <div className="background-dark">
        <div className="sizer p-t-0">
          <div className="container lg-p-x-0">
            <div className="row align-items-center justify-content-center">
              <FirstHeader firstHeader={home?.data?.first_header} />
              <div className="block-type-feature text-center col-4">
                <div className="block box-shadow-none">
                  <div className="feature column-center">
                    <PartialLogin
                      checkout_url={annualPromotion.data?.attributes?.checkout_url}
                      type={TypePartial.AnnualSubscription}
                      render={
                        <Img
                          src={strapiImage(annualPromotion.data?.attributes?.image?.data?.attributes?.url)}
                          width={416}
                          height={267}
                          alt={"Yearly Subscription"} />
                      } />
                    <div className='row p-w-100 justify-content-around'>
                      <PartialLogin
                        checkout_url={annualPromotion.data?.attributes?.checkout_url}
                        type={TypePartial.AnnualSubscription}
                        render={
                          <a className='btn btn-large'>
                            <span className='f-s-14'>
                              ซื้อแพ็กเกจรายปี
                            </span>
                          </a>
                        } />
                      {/* waiting for free trial */}
                      <PartialLogin
                        checkout_url={'free-trial'}
                        type={TypePartial.None}
                        render={
                          <a className='btn btn-large btn-black-outlet'>
                            <i className='fal fa-megaphone f-s-14 m-r-5' />
                            <span className="f-s-14">
                              ทดลองเรียนฟรี
                            </span>
                          </a>
                        } />
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-type-image text-center col-8">
                <div className="box-shadow-none">
                  <br />
                  <VideoPlayer props={{ ...home.data?.home_video }} imageStrapi={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-light">
        <div className="sizer">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <SecondHeader secondHeader={home?.data?.second_header} />
              <div className="block-type-feature text-center col-5">
                <div className="block box-shadow-none">
                  <div className="feature column-center">
                    <PartialLogin
                      checkout_url={annualPromotion.data?.attributes?.checkout_url}
                      type={TypePartial.AnnualSubscription}
                      render={
                        <Img
                          src={strapiImage(annualPromotion.data?.attributes?.image_mobile?.data?.attributes?.url)}
                          width={400}
                          height={400}
                          alt={'Yearly Subscription'} />
                      } />
                    <PartialLogin
                      checkout_url={annualPromotion.data?.attributes?.checkout_url}
                      type={TypePartial.AnnualSubscription}
                      render={
                        <a className='btn btn-solid btn-large btn-auto'>
                          ซื้อแพ็กเกจรายปี
                        </a>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="block-type-code text-left col-6">
                <SlideCourse settings={settings} slideCourses={carousel.data} slideView={1} imageWidth={400} imageHeight={600} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-dark">
        <div className="sizer">
          <div className="container">
            <h3 className="text-center color-primary ipad-line-height f-s-34 l-h-13">
              <strong>
                <span className="ipad-f-s-24 sm-f-s-18">
                  {home.data?.trial_title}
                </span>
              </strong>
            </h3>
            <h5 className='text-center text-white'>{home.data?.trial_description}</h5>

            <div className='flex-column-center'>
              <div className='dropdown'>
                <Img src={home.data?.trial_image?.url} width={764} height={369} />
                <div className='shadow'>
                  <Img src="/free-trial/shadowImg.png" width={764} height={369} />
                </div>
              </div>
            </div>
            <div className='flex-column-center'><Link href="/free-trial"><div className='free-trial-button m-t-20' >ทดลองเรียนฟรี</div></Link></div>
          </div>

        </div>
      </div>
      <div className="background-dark">
        <div className="sizer">
          <div className="container">
            <Specific specifics={home.data?.information} />
          </div>
        </div>
      </div>
      <div className="background-light">
        <div className="sizer">
          <div className="container">
            <CoursesUpdate coursesSoon={home.data?.courses_soon} coursesLatest={home.data?.courses_latest} />
          </div>
        </div>
      </div>
      <div className="background-dark">
        <StudentReviews reviewStudents={review?.data?.student} />
        <div className="sizer p-t-20">
          <div className="container">
            <ShopeeReviews shopeeInfo={home.data?.shopee_info} shopee={home.data?.shopee} />
          </div>
        </div>
      </div>
      <div className="sizer background-light">
        <div className="container">
          <MyStudent myStudents={home.data?.my_student} />
        </div>
      </div>
      <div className="sizer background-light">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="block-type-text text-left col-8">
              <div className="block box-shadow-none">
                <h2 className="text-center f-w-700">
                  <span className="color-primary">
                    คำถามที่พบบ่อย
                  </span>
                </h2>
              </div>
            </div>
            {showAccordion && home.data?.q_and_a?.map((value, index) => {
              return (
                <Accordion key={index}
                  title={value.title}
                  clickFunction={() => setClickedAccor(index)}
                  description={value.description}
                  col={8}
                  showDetails={value.showDetails}
                  color={Color.light} />
              )
            })}
          </div>
        </div>
      </div>
      <FooterBrand />
      <Footer />
    </div >
    </>
  )
}

export async function getStaticProps() {
  return {
    props: {
      carousel: await carouselApi(),
      home: await homeApi(),
      review: await reviewApi(),
      annualPromotion: await annualPromotionApi(),
    }
  };
}
