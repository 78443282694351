import stringSplitLine from "../../functions/stringSplitLine";

export default function SecondHeader({
  secondHeader,
}: {
  secondHeader: string;
}) {
  const secondHeaderLocal = stringSplitLine(secondHeader);
  return (
    <div className="block-type-text text-left col-12">
      <div className="block box-shadow-none">
        <h3 className="text-center ipad-line-height f-s-34 l-h-13">
          <strong>
            {secondHeaderLocal.map((value, index) => {
              if (index <= 0) {
                return (
                  <span key={index} className="color-primary ipad-f-s-32 sm-f-s-22 l-h-13">
                    {value} <br />
                  </span>
                );
              } else {
                return (
                  <span key={index} className="color-primary sm-none l-h-13">{value}</span>
                );
              }
            })}
          </strong>
        </h3>
      </div>
    </div>
  );
}
