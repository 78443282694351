import { strapiImage } from "../../apiStrapi/models/contact"
import { CoursesLatest, CoursesSoon } from "../../apiStrapi/models/contentType/home"
import Img from "../image"

export default function CoursesUpdate({ coursesSoon, coursesLatest }: { coursesSoon: CoursesSoon[], coursesLatest: CoursesLatest[] }) {
  return (
    <div className="row align-items-center justify-content-center">
      {Boolean(coursesLatest.length) && (
        <>
          <div className="block-type-text text-center col-12">
            <div className="block box-shadow-none">
              <h2 className="f-w-700">
                <span className="color-primary ipad-f-s-32 sm-f-s-28">
                  คอร์สเปิดตัวล่าสุด
                </span>
              </h2>
            </div>
          </div>
          <div className="">
            {coursesLatest?.map((value, index) => {
              return (
                <div key={index} className="block-type-image">
                  <div className="block box-shadow-none row">
                    <div className="coming-soon p-15 col-6">
                      <a href={`/course/${value.course?.slug}`}>
                        <Img className="feature-image"
                          src={strapiImage(value.image?.url)}
                          width={600}
                          height={337.5}
                          alt={value.name}
                        />
                      </a>
                    </div>
                    <div className="coming-soon p-15 col-6 sm-none">
                      <h4 className="f-w-700 color-primary m-0">
                        คอร์ส {value?.name}
                      </h4>
                      <div className="f-w-700 color-primary f-s-18 m-0">
                          สอนโดย {value?.course?.speaker_name}
                      </div>
                      <a
                        href={`/course/${value.course?.slug}`}
                        className="btn btn-solid btn-auto btn-large">
                        ดูรายละเอียดคอร์ส
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      {Boolean(coursesSoon.length) && (
        <>
          <div className="block-type-text text-center col-12">
            <div className="block box-shadow-none">
              <h2 className="f-w-700">
                <span className="color-primary ipad-f-s-32 sm-f-s-28">
                  คอร์สที่กำลังจะเปิดตัว
                </span>
              </h2>
            </div>
          </div>
          <div className="inline-courses">
            {coursesSoon?.map((value, index) => {
              return (
                <div key={index} className="block-type-feature text-center courses-latest">
                  <div className="block box-shadow-none row">
                    <div className="feature column-center">
                      <Img className="image-image"
                        src={strapiImage(value.image?.url)}
                        alt={value.name}
                        width={603}
                        height={339.18}
                      />
                        <div className="f-w-700 color-primary m-t-10 f-s-18 m-b-2">
                          คอร์ส {value?.name}
                        </div>
                    </div>
                  </div>
                </div>
              )
            })
            }
          </div>
          <a
            href={"/courses"}
            className="btn btn-large lg-p-x-40 p-y-14 lg-m-t-30">
            <span className="f-s-21 sm-f-s-18">
              ดูคอร์สทั้งหมด
            </span>
          </a>
        </>
      )}
    </div>
  )
}
