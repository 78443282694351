import stringSplitLine from "../../functions/stringSplitLine";

export default function FirstHeader({ firstHeader }: { firstHeader: string }) {
  const firstHeaderLocal = stringSplitLine(firstHeader);
  return (
    <div className="text-center col-12">
      {firstHeaderLocal.map((value, index) => {
        if (index <= 0) {
          return (
            <h2 key={index} className="sm-f-s-24 sm-line-height sm-m-b-20 l-h-13 m-b-1">
              <strong>
                <span className="color-primary">{value}</span>
              </strong>
            </h2>
          );
        } else {
          return (
            <h5 key={index} className="sm-line-height">
              <em>
                <strong>
                  <span className="color-primary sm-f-s-16">{value}</span>
                </strong>
              </em>
            </h5>
          );
        }
      })}
    </div>
  );
}
